import gql from 'graphql-tag'
import artistDetailsFragment from './artistDetails.fragment'
import {
  artistEventInvitationsFragment,
  promoterEventInvitationsFragment,
  venueEventInvitationsFragment,
} from './eventInvitations.fragment'
import venueDetailsFragment from './venueDetails.fragment'
import { promoterDetailsFragment } from './promoterDetails.fragment'
import { UserDetailsFragment } from './userDetails.fragment.gen'

export default gql`
  fragment UserDetails on User {
    id
    name
    picture
    slug
    email
    csrfToken

    city {
      id
      name
    }

    selectedProfile {
      ... on Artist {
        ...ArtistDetails
        ...ArtistEventInvitations
      }

      ... on Venue {
        ...VenueDetails
        ...VenueEventInvitations
      }

      ... on Promoter {
        ...PromoterDetails
        ...PromoterEventInvitations
      }

      ... on User {
        __typename
        id
        name
        slug
        picture
      }
    }

    profiles {
      ... on Artist {
        ...ArtistDetails
      }

      ... on Venue {
        ...VenueDetails
      }

      ... on Promoter {
        ...PromoterDetails
      }

      ... on User {
        __typename
        id
        name
        slug
        picture
      }
    }
  }

  ${artistDetailsFragment}
  ${venueDetailsFragment}
  ${promoterDetailsFragment}
  ${artistEventInvitationsFragment}
  ${venueEventInvitationsFragment}
  ${promoterEventInvitationsFragment}
`

export type UserProfile = UserDetailsFragment['profiles'][0]
