import gql from 'graphql-tag'

import genreDetailsFragment from './genreDetails.fragment'
import linkDetailsFragment from './linkDetails.fragment'

export default gql`
  fragment ArtistDetails on Artist {
    __typename
    id
    name
    description
    picture
    slug
    genres {
      ...GenreDetails
    }
    links {
      ...LinkDetails
    }
  }

  ${genreDetailsFragment}
  ${linkDetailsFragment}
`

export const artistSearchDetailsFragment = gql`
  fragment ArtistSearchDetails on Artist {
    __typename
    id
    name
    slug
    genres {
      ...GenreDetails
    }
  }

  ${genreDetailsFragment}
`
