import gql from 'graphql-tag'

import userDetailsFragment from '../../fragments/userDetails.fragment'

export default gql`
  query currentUser {
    currentUser {
      ...UserDetails
    }
  }

  ${userDetailsFragment}
`
