import gql from 'graphql-tag'

import linkDetailsFragment from './linkDetails.fragment'

export const promoterDetailsFragment = gql`
  fragment PromoterDetails on Promoter {
    __typename
    id
    name
    description
    picture
    slug
    links {
      ...LinkDetails
    }
  }

  ${linkDetailsFragment}
`

export const promoterSearchDetailsFragment = gql`
  fragment PromoterSearchDetails on Promoter {
    __typename
    id
    name
    picture
    slug
  }
`
