import gql from 'graphql-tag'

export const artistEventInvitationsFragment = gql`
  fragment ArtistEventInvitations on Artist {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }

        ... on Venue {
          id
          name
          slug
          picture
        }

        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`

export const venueEventInvitationsFragment = gql`
  fragment VenueEventInvitations on Venue {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }

        ... on Venue {
          id
          name
          slug
          picture
        }

        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`

export const promoterEventInvitationsFragment = gql`
  fragment PromoterEventInvitations on Promoter {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }

        ... on Venue {
          id
          name
          slug
          picture
        }

        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`
