import gql from 'graphql-tag'
import cityDetailsFragment from './cityDetails.fragment'
import linkDetailsFragment from './linkDetails.fragment'

export default gql`
  fragment VenueDetails on Venue {
    __typename
    id
    name
    description
    picture
    address
    slug
    audienceCapacity
    ageRestriction
    city {
      ...CityDetails
    }
    links {
      ...LinkDetails
    }
  }

  ${cityDetailsFragment}
  ${linkDetailsFragment}
`
export const venueSearchDetailsFragment = gql`
  fragment VenueSearchDetails on Venue {
    id
    name
    picture
    slug
    audienceCapacity

    city {
      ...CityDetails
    }
  }

  ${cityDetailsFragment}
`
